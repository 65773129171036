//Constants needed to connect api services
export const apiEndPoint = 'https://ctrl-despacho-staging-api.gfcasesores.com/';
export const base_url = `/#/`;
export const userRoles = {
  ADMINISTRADOR: 'Administrador',
  AUDITOR: 'Auditor',
  OPERADOR: 'Operador',
  CLIENTE: 'Cliente',
};
export const MESSAGES_SERVICES = {
  COMMENTS: 'comments',
  OBSERVATIONS: 'observations',
  RECOMENDATIONS: 'recomendations',
  CONCLUSIONS: 'conclusions',
};
export const serviceName = {
  CONTABILIDAD: 'Contabilidad',
  AUDITORIA: 'Auditoria',
};
export default {
  apiEndPoint,
  base_url,
  userRoles,
  MESSAGES_SERVICES,
  serviceName,
};
