<template>
  <div class="table-container" :class="isLoading ? 'loading-table' : ''">
    <div v-if="header" class="table-title">
      <span class="header-title">{{ header }}</span>
      <template v-if="$slots.header">
        <slot name="header" />
      </template>
    </div>
    <div class="extras-container">
      <slot name="extra" />
    </div>
    <template v-if="$slots.underHeader">
      <slot name="underHeader" />
    </template>
    <template v-if="$slots.empty && isEmpty">
      <slot name="empty" />
    </template>
    <template v-else>
      <template v-if="!hideTable && hideTableOnLoading">
        <div class="empty-content w-100">
          <o-loading :active="true" :full-page="false">
            <o-icon icon="loading" size="large" spin> </o-icon>
          </o-loading>
        </div>
      </template>
      <o-table
        v-bind="{ ...tableProps }"
        ref="oTable"
        :loading="isLoading"
        :focusable="focusable"
        :data="tableData"
        :pagination="pagination.total"
        :current-page="pagination.currentPage"
        :paginated="isPaginated"
        :per-page="perPage"
        :total="pagination.total"
        sort-icon="chevron-up"
        mobile-breakpoint="768px"
        backend-pagination
        backend-sorting
        @sort="onSort"
        @page-change="onPageChange"
      >
        <slot />
        <template #empty>
          <Empty v-if="!isLoading" :empty-message="emptyMessage" />
        </template>
        <template #detail="props">
          <slot name="detail" v-bind="{ ...props.row }" />
        </template>
      </o-table>
    </template>
  </div>
</template>
<script>
import {
  computed,
  reactive,
  ref,
  toRefs,
  watch,
  getCurrentInstance,
  onMounted,
  onBeforeMount,
  nextTick,
} from 'vue';
import { Empty } from '@/components';
import { usePickProps } from '@/components/conf/composables';
import { useRouter, useRoute } from 'vue-router';

export default {
  components: {
    Empty,
  },
  props: {
    apiUrl: { type: [String, Function], default: '' },
    hideTableOnLoading: { type: Boolean, default: false },
    updateInterval: { type: Number, default: 0 },
    data: { type: Array, default: () => [] },
    focusable: { type: Boolean, default: true },
    header: { type: String, default: '' },
    loading: { type: Boolean, default: false },
    paginated: { type: Boolean, default: false },
    params: { type: Array, default: () => [] },
    emptyMessage: { type: String, default: 'No se encontraron resultados' },
    perPage: { type: Number, default: 15 },
    defaultSort: { type: String, default: '' },
  },
  emits: ['update:loading', 'page-change', 'update:data'],
  setup(props, { emit, attrs }) {
    const oTable = ref(null);
    const router = useRouter();
    const currentRoute = ref(useRoute());
    const urlParams = computed(() => {
      return currentRoute?.value?.query;
    });
    const { proxy } = getCurrentInstance();
    const Api = proxy?.Api;
    const { loading, params } = toRefs(props);
    const isLoading = ref(props.loading);
    const tableData = ref(props.data);
    const areParamsAlreadySet = ref(false);
    const pagination = reactive({
      total: 0,
      perPage: props.perPage,
      currentPage: 1,
    });
    const sortCustome = reactive({ sortBy: '', params: [], direction: '' });
    const cancelToken = ref(null);
    const isPaginated = computed(() => {
      return (
        (props.paginated && !!tableData.value.length) ||
        (pagination.currentPage !== 1 && !isLoading.value)
      );
    });
    const timeout = ref(null);
    const { propsPicked: tableProps } = usePickProps('TableProps', { props, attrs });

    const isEmpty = () => computed(() => tableData.value.length && !loading.value);
    const queryParams = computed(() => {
      const { currentPage, perPage } = pagination;
      const aParams = [
        `page=${currentPage}`,
        `per_page=${perPage}`,
        ...params.value,
        ...sortCustome.params,
      ];
      return `?${aParams.join('&')}`;
    });
    const cancelRequest = (message = 'Avoid multiple') => {
      if (cancelToken.value) {
        cancelToken.value.cancel(message);
        isLoading.value = false;
      }
    };
    const hideTable = computed(() => {
      return props.hideTableOnLoading ? !isLoading.value : true;
    });
    const getTableData = async (isUpdate, onNextPage) => {
      if (!isUpdate || onNextPage) isLoading.value = true;
      try {
        let { data } = await Api.get(`${props.apiUrl}${queryParams.value}`, {
          cancelToken: cancelToken.value.token,
        });
        tableData.value = [];
        await nextTick();
        tableData.value = data;
      } catch (error) {
        console.error(error);
      }
      isLoading.value = false;
    };
    const onPageChange = async (page) => {
      emit('page-change', page);
      pagination.currentPage = page;
      router.push({ query: { p: page } });
      cancelRequest();
      clearTimeout(timeout.value);
      cancelToken.value = Api.cancelToken;
      timeout.value = setTimeout(async () => {
        getTableData();
      }, 50);
    };
    const getTotal = async () => {
      try {
        const { data } = await Api.get(`/${props.apiUrl}/total-items${queryParams.value}`);
        pagination.total = ((data && data[0]) || data).total_items;
      } catch (error) {
        console.error(error);
      }
    };
    const getInitialPage = () => {
      const initialPage = urlParams?.value?.p ? parseInt(urlParams?.value.p, 10) : 0;
      if (initialPage > 0) return (pagination.currentPage = initialPage);
      setPageToFirst();
    };
    const setPageToFirst = () => {
      if (areParamsAlreadySet.value) return router.push({ query: { p: 1 } });
      areParamsAlreadySet.value = true;
    };

    const getData = async (clear) => {
      isLoading.value = true;
      if (props.apiUrl) {
        cancelRequest();
        clearTimeout(timeout.value);
        cancelToken.value = Api.cancelToken;
        timeout.value = setTimeout(async () => {
          getInitialPage();
          getTotal();
          getTableData();
          if (clear) {
            tableData.value = [];
          }
        }, 50);
      }
    };
    const reload = async (clear = false) => await getData(clear);

    const onSort = (name) => {
      const isSorting = name === sortCustome.sortBy;
      if (!isSorting || (sortCustome.direction === 'DESC' && isSorting)) {
        sortCustome.direction = 'ASC';
        sortCustome.params = [`sort=${name}`];
        sortCustome.sortBy = name;
      } else if (isSorting && sortCustome.direction === 'ASC') {
        sortCustome.direction = 'DESC';
        sortCustome.params = [`sort=-${name}`];
      }
    };

    watch(loading, (value) => (isLoading.value = value));
    watch(isLoading, (value) => emit('update:loading', value));
    watch(tableData, (value) => emit('update:data', value));
    watch(params, (newVals, oldVals) => {
      if (newVals.join(',') === oldVals.join(',')) return;
      setPageToFirst();
      getData();
    });
    watch(sortCustome, () => {
      setPageToFirst();
      getData();
    });

    onMounted(() => {
      if (!urlParams?.value?.p) setPageToFirst();
      getData();
    });

    onBeforeMount(() => {
      if (props.defaultSort) sortCustome.params = [`sort=${props.defaultSort}`];
    });

    return {
      hideTable,
      isLoading,
      pagination,
      tableData,
      isEmpty,
      onPageChange,
      reload,
      oTable,
      onSort,
      tableProps,
      queryParams,
      oParams: params.value,
      cancelToken,
      timeout,
      isPaginated,
    };
  },
};
</script>
<style lang="sass" scoped>
.table-container
  min-height: 500px
  .extras-container
    position: relative
    bottom: 14px
    align-items: center
    display: flex
    justify-content: right
  > .empty-content
    position: relative
    height: 62vh
    display: flex
    justify-content: center
    flex-direction: column
    align-items: center
  &.loading-table :deep(.table-wrapper)
    min-height: 45vh
  .table-title
    display: flex
    padding-top: 3px
    justify-content: space-between
    padding-bottom: 13px
    margin-bottom: 29px
    border-bottom: solid 2px #858A8D
    .header-title
      font-weight: 700
      color: $black
      font-size: $fh-md
      line-height: 28px
  :deep(.table-wrapper)
    > .o-load
      z-index: 0
      min-width: 70vw
    thead
      tr th
        border-bottom: none
        background-color: $black
        padding: 13.4px 32px
        font-weight: 600
        font-size: $f-sm
        color: $white
        &.is-sortable
          .sort-icon
            left: 90%
            width: 0px
            display: none
            opacity: 0
            .icon
              margin: 0
            &:before
              font-size: 19px
          &:hover .sort-icon
            display: flex !important
            opacity: 1
        &.is-current-sort .sort-icon
          opacity: 1
          display: block
        span
          display: flex
          width: 100%
          flex-direction: row
          text-align: center
          align-items: center
          justify-content: center
          white-space: nowrap
          span
            width: 15px !important
          .icon
            width: 15px !important

    tbody
      tr
        background-color: $color-alice-blue
        &.detail
          z-index: 1
          position: relative
        td
          border: 0
          font-weight: 400
          font-size: $f-sm
          padding: 11.5px 24px
          min-height: 50px
          vertical-align: middle !important
          color: $black
          &.chevron-cell
            text-align: center
            .icon .mdi::before
              color: $black
        &:nth-child(odd)
          background-color: $white
  :deep(.level)
    width: 99%
    justify-content: center
    a.pagination-link
      width: 40px
      height: 40px
      border: 0
      &:active
        box-shadow: none
      &:hover .icon i::before
        font-size: 28px
    .pagination-next
      order: 4
    .pagination-list
      .pagination-link
        &:hover
          font-weight: 600
        &.is-current
          border: solid 1px $black
          background-color: $black
          color: $color-alice-blue
</style>
